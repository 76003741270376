import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";
import image4 from "./image4.jpg";
import image5 from "./image5.jpg";
import image6 from "./image6.jpg";
import image7 from "./image7.jpg";
import image8 from "./image8.jpg";
import image9 from "./image9.jpg";
import image10 from "./image10.jpg";
import image11 from "./image11.jpg";
import image12 from "./image12.jpg";
import image13 from "./image13.jpg";
import image14 from "./image14.jpg";
import image15 from "./image15.jpg";
import image16 from "./image16.jpg";
import image17 from "./image17.jpg";
import image18 from "./image18.jpg";
import image19 from "./image19.jpg";
import image20 from "./image20.jpg";
import image21 from "./image21.jpg";
import image22 from "./image22.jpg";
import image23 from "./image23.jpg";
import image24 from "./image24.jpg";
import image25 from "./image25.jpg";
import image26 from "./image26.jpg";
import image27 from "./image27.jpg";
import image28 from "./image28.jpg";
import image29 from "./image29.jpg";
import image30 from "./image30.jpg";
import image31 from "./image31.jpg";
import image32 from "./image32.jpg";
import image33 from "./image33.jpg";
import image34 from "./image34.jpg";
import image35 from "./image35.jpg";
import image36 from "./image36.jpg";
import image37 from "./image37.jpg";
import image38 from "./image38.jpg";
import image39 from "./image39.jpg";
import image40 from "./image40.jpg";
import image41 from "./image41.jpg";
import image42 from "./image42.jpg";
import image43 from "./image43.jpg";
import image44 from "./image44.jpg";
import image45 from "./image45.jpg";
import image46 from "./image46.jpg";
import image47 from "./image47.jpg";
import image48 from "./image48.jpg";
import image49 from "./image49.jpg";
import image50 from "./image50.jpg";
import image51 from "./image51.jpg";
import image52 from "./image52.jpg";
import image53 from "./image53.jpg";
import image54 from "./image54.jpg";
import image55 from "./image55.jpg";
import image56 from "./image56.jpg";
import image57 from "./image57.jpg";
import image58 from "./image58.jpg";
import image59 from "./image59.jpg";
import image60 from "./image60.jpg";
import image61 from "./image61.jpg";
import image62 from "./image62.jpg";
import image63 from "./image63.jpg";
import image64 from "./image64.jpg";
import image65 from "./image65.jpg";
import image66 from "./image66.jpg";
import image67 from "./image67.jpg";
import image68 from "./image68.jpg";
import image69 from "./image69.jpg";
import image70 from "./image70.jpg";
import image71 from "./image71.jpg";
import image72 from "./image72.jpg";
import image73 from "./image73.jpg";
import image74 from "./image74.jpg";
import image75 from "./image75.jpg";
import vid1 from "./video1.mp4";
import vid2 from "./video2.mp4";
import vid3 from "./video3.mp4";
import vid4 from "./video4.mp4";
import vid5 from "./video5.mp4";

const images = [
  {
    src: image1,
    alt: 'Description of image 1',
  },
  {
    src: image2,
    alt: 'Description of image 2',
  },
  {
    src: image3,
    alt: 'Description of image 3',
  },
  {
    src: image4,
    alt: 'Description of image 4',
  },
  {
    src: image5,
    alt: 'Description of image 5',
  },
  {
    src: image6,
    alt: 'Description of image 6',
  },
  {
    src: image7,
    alt: 'Description of image 7',
  },
  {
    src: image8,
    alt: 'Description of image 8',
  },
  {
    src: image9,
    alt: 'Description of image 9',
  },
  {
    src: image10,
    alt: 'Description of image 10',
  },
  {
    src: image11,
    alt: 'Description of image 11',
  },
  {
    src: image12,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image13,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image14,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image15,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image16,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image17,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image18,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image19,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image20,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image21,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image22,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image23,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image24,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image25,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image26,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image27,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image28,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image29,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image30,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image31,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image32,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image33,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image34,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image35,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image36,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image37,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image38,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image39,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image40,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image41,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image42,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image43,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image44,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image45,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image46,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image47,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image48,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image49,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image50,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image51,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image52,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image53,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image54,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image55,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image57,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image58,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image59,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image60,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image61,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image62,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image63,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image64,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image65,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image66,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image67,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image68,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image69,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image70,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image71,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image72,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image73,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image74,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  {
    src: image75,
    alt: 'Detailing Or Tint or Decal Picture',
  },
  // {
  //   src: vid1,
  //   type: 'video',
  //   title: 'Detailing or Tint or Decal Video 1',
  // },
  // {
  //   src: vid2,
  //   type: 'video',
  //   title: 'Detailing or Tint or Decal Video 2',
  // },
  // {
  //   src: vid3,
  //   type: 'video',
  //   title: 'Detailing or Tint or Decal Video 3',
  // },
  // {
  //   src: vid4,
  //   type: 'video',
  //   title: 'Detailing or Tint or Decal Video 4',
  // },
  // {
  //   src: vid5,
  //   type: 'video',
  //   title: 'Detailing or Tint or Decal Video 5',
  // },
  // ... more media
];


export default images;